import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilChartLine,
  cilFile,
  cilGraph,
  cilHouse,
  cilList,
  cilMoney,
  cilNewspaper,
  cilPeople,
  cilPuzzle,
  cilQrCode,
  cilRss,
  cilSpreadsheet,
  cilTags,
  cibGoogle,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavTitle,
    name: 'Category',
  },
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilGraph} customClassName="nav-icon" />,
    permission: 'dashboard_page',
  },
  {
    component: CNavItem,
    name: 'Revenue',
    to: '/revenue',
    icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
    permission: 'revenue_page',
  },
  {
    component: CNavItem,
    name: 'Order List',
    to: '/order-list',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    permission: 'order_list_page',
  },
  {
    component: CNavItem,
    name: 'Transactions',
    to: '/transactions',
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    permission: 'transaction_page',
  },
  {
    component: CNavItem,
    name: 'Callback Payments',
    to: '/callback-payments',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    permission: 'callback_payments_page',
  },
  {
    component: CNavItem,
    name: 'Merchant',
    to: '/merchant',
    icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
    permission: 'merchant_page',
  },
  {
    component: CNavItem,
    name: 'Sales Report',
    to: '/salesReport',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    permission: 'sale_report_page',
  },
  {
    component: CNavItem,
    name: 'Members',
    to: '/members',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    permission: 'member_page',
  },
  {
    component: CNavItem,
    name: 'KYC',
    to: '/kyc',
    icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
    permission: 'kyc_page',
  },
  {
    component: CNavItem,
    name: 'Promotion',
    to: '/promotions',
    icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
    permission: 'promotion_page',
  },
  {
    component: CNavItem,
    name: 'Payment Form',
    to: '/payment-form',
    icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
    permission: 'kyc_page',
  },
  {
    component: CNavItem,
    name: 'Merchant Payment',
    to: '/merchant-payment',
    icon: <CIcon icon={cilQrCode} customClassName="nav-icon" />,
    permission: 'merchant_payment_page',
  },
  {
    component: CNavItem,
    name: 'Broadcast',
    to: '/broadcast',
    icon: <CIcon icon={cilRss} customClassName="nav-icon" />,
    permission: 'broadcast_page',
  },
  {
    component: CNavItem,
    name: 'Excel',
    to: '/generate-excel',
    icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
    permission: 'excel_page',
  },
  {
    component: CNavItem,
    name: 'Google Pin',
    to: '/google-pin',
    icon: <CIcon icon={cibGoogle} customClassName="nav-icon" />,
    permission: 'google_pin',
  },
  {
    component: CNavTitle,
    name: 'Admin',
    permission: 'admin_page',
  },
  {
    component: CNavGroup,
    name: 'Admin',
    // to: '/base',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    permission: 'admin_page',
    items: [
      {
        component: CNavItem,
        name: 'Admin List',
        to: '/admin-list',
      },
    ],
  },
]

export const permissions = _nav.filter((item) => item.permission).map((item) => item.permission)

export default _nav
