import React from 'react'
import PropTypes from 'prop-types'
import { CButton, CModal, CModalBody } from '@coreui/react'
import failedImg from 'src/assets/images/wrong.png'
import { useModalFailed } from 'src/store/useModalFailed'
ModalFailed.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
function ModalFailed({ isOpen = false, message, onClose }) {
  return (
    <CModal
      visible={isOpen}
      onClose={onClose}
      backdrop="static"
      centered
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 9999,
      }}
    >
      <CModalBody
        style={{
          width: '450px',
          height: '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div style={{ position: 'absolute', right: 8, top: 8 }}>
          <CButton
            style={{
              color: 'red',
              backgroundColor: 'transparent',
              border: '0px solid red',
              borderColor: 'red',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              fontWeight: 'bold',
            }}
            onClick={onClose}
          >
            X
          </CButton>
        </div>
        <img src={failedImg} alt="Error" width="100px" />
        <h3 style={{ marginTop: '20px' }}>เกิดข้อผิดพลาด</h3>
        <body>{message}</body>
      </CModalBody>
    </CModal>
  )
}

ModalFailedProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function ModalFailedProvider({ children }) {
  const { isOpen, message, closeFailedModal } = useModalFailed()
  return (
    <div>
      {children}
      <ModalFailed isOpen={isOpen} message={message} onClose={closeFailedModal} />
    </div>
  )
}
