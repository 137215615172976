import { create } from 'zustand'

export const useAuthen = create((set) => ({
  isAuthenticated: false,
  uuid: '',
  login: () => set({ isAuthenticated: true }),
  logout: () => set({ isAuthenticated: false }),
  setUuid: (uuid) => set({ uuid: uuid }),
  role: '',
  setRole: (role) => set({ role: role }),
  userData: {},
  setUserData: (data) => set({ userData: data }),
}))
