export const filterNavItems = (navItems, permissions) => {
  return navItems.reduce((filteredNav, navItem) => {
    if (navItem.permission) {
      if (permissions[navItem.permission]) {
        filteredNav.push(navItem)
      }
    } else if (navItem.items) {
      const filteredItems = filterNavItems(navItem.items, permissions)
      if (filteredItems.length > 0) {
        filteredNav.push({ ...navItem, items: filteredItems })
      }
    } else {
      filteredNav.push(navItem)
    }
    return filteredNav
  }, [])
}
