import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { getAccessPage, getAdmin } from '../service/authenService'
import { getCookie } from '../service/config'
import { useAccessPage } from '../store/useAccessPage'
import { useAuthen } from '../store/useAuth'
import { filterNavItems } from '../util/filterAccessPage'
import navigation from '../_nav'
import { useModalLoading } from 'src/store/useModalLoading'
export const PresistLogin = () => {
  const { login, logout, isAuthenticated, setUuid, setRole, setUserData } = useAuthen()
  const { setAccessPage } = useAccessPage()
  const { openModal, closeModal } = useModalLoading()
  useEffect(() => {
    openModal()
    if (!isAuthenticated) {
      const token = getCookie('token')
      if (token) {
        login()
        getAccessPage(token)
          .then(({ data }) => {
            const accessPage = filterNavItems(navigation, data)
            setUuid(data.uuid)
            getAdmin(data.uuid).then((res) => {
              setUserData(res.data)
            })
            setRole(data.role)
            setAccessPage(accessPage)
            closeModal()
          })
          .catch((error) => {
            closeModal()
          })
      } else {
        logout()
        closeModal()
      }
    }
  }, [isAuthenticated, login, logout, setAccessPage])

  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  )
}
