import React from 'react'
import { useModalLoading } from 'src/store/useModalLoading'
import PropTypes from 'prop-types'
import { CModal, CModalBody } from '@coreui/react'
import { Spin } from 'antd'

ModalLoading.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

function ModalLoading({ isOpen = false }) {
  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 9999,
      }}
    >
      <CModalBody
        style={{
          width: '450px',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" style={{}} />
      </CModalBody>
    </CModal>
  )
}

ModalLoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function ModalLoadingProvider({ children }) {
  const { isLoading } = useModalLoading()
  return (
    <div>
      {children}
      <ModalLoading isOpen={isLoading} />
    </div>
  )
}
