import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { ModalLoadingProvider } from './components/ModalLoading'
import { ModalFailedProvider } from './components/ModalFailed'
import { BrowserRouter } from 'react-router-dom'

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <ModalLoadingProvider>
      <ModalFailedProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ModalFailedProvider>
    </ModalLoadingProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
