import axios from 'axios'
import { axiosInstance } from './config'

export const login = (data) => {
  return axios.post('/auth/login', data, {
    baseURL: process.env.REACT_APP_BASE_API_URL,
  })
}

export const getAccessPage = (token) => {
  return axiosInstance.get('/admin/roleaccess', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getAdmin = (uuid) => {
  return axiosInstance.get(`/admin/${uuid}`)
}
